import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import GUI from 'lil-gui'
import gsap from 'gsap'
import { solidifyMacbook, wireframeSim } from './effects.js'

export const setState_home = (camera, v, scene, objects) => {

        // scene.background = new THREE.Color("#000");

        let tl = gsap.timeline();
      
        tl.to(camera.rotation, {
          duration: 1.5,
          ease: "power2.inOut",
          x: Math.PI * 1.05,
          y: Math.PI * 2,
          z: Math.PI,
        });
      
        tl.to(
          camera.position,
          {
            duration: 1,
            ease: "power2.inOut",
            x:  0.762,
            y:  0.131, 
            z:  -0.171
          },
          0
        );
      
        tl.to(camera.position, {
          duration: 1.5,
          ease: "power2.inOut",
          x: 0.762,
          y: 0.395,
          z: -1.13,
        });
      
        tl.to(
          v,
          {
            duration: 1.5,
            ease: "power2.inOut",
            wf: 1,
            onUpdate: () => wireframeSim(v.wf, scene, objects),
          },
          1
        );
      
        tl.to(
          v,
          {
            duration: 1.5,
            ease: "power2.inOut",
            wf: 1,
            onUpdate: () => solidifyMacbook(v.wf, scene, objects),
          },
          0
        );
      };