import * as THREE from "three";
import GUI from 'lil-gui'
import { buildScene } from "./buildScene.js";
import { loadModels } from "./loadModels.js";
import { setState_tech } from "./tech_state.js";
import { wireframeSim } from "./effects.js";
import { setState_home } from "./home_state.js";
import { setState_macside, setState_overview, setState_projects } from "./projects_state.js";


import './style.css';
import 'highlight.js/styles/github.css';
import typescriptHTML from './tech/typescript.md';
import graphQlHTML from './tech/graphQl.md';
import redwoodHTML from './tech/redwood.md';
import lambdaHTML from './tech/lambda.md';
import figmaHTML from './tech/figma.md';
import reactHTML from './tech/react.md';
import threejsHTML from './tech/threejs.md';
import blenderHTML from './tech/blender.md';

import contactHTML from './contact/contact.md';




const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
  sceneWidth: 14,
  sceneHeight: 14,
  sceneDepth: 14,

  wallThickness: (1 / 100) * 10,

  roomWidth: 7,
  roomDepth: 5,
  roomHeight: 3.5,

  tableGap: 2,
  tableWidth: 3,
  tableDepth: 1.5,
  tableThickness: (1 / 100) * 3,
  tableHeight: 0.8,

  plantWidth: 0.3,
  plantHeight: 0.5,

  macbookHeight: (1 / 100) * 1.68,
  macbookWidth: (1 / 100) * 35.57,
  macbookDepth: (1 / 100) * 24.81,

  windowWidth: 1.5,
  windowHeight: 2,

  windowLedgeHeight: 1,

  mapWidth: 1.5,
  mapDepth: 0.95,

  coneHeight: 0.04,
  coneRadius: 0.02

};

const objects = {
  plant: null,
  macbookPlaceholder: null,

  table: null,
  tableSurface: null,

  flowerModel: null,
  flowerModelWireframe: null,
  macbookModel: null,
  macbookModelSolid: null,

  decalAWS: null,
  decalGraphQL: null,

    map: null,

  indicator: null
};


var mapLeft = (sizes.mapWidth * 0.5);
var mapTop = (sizes.mapDepth * 0.5);

const mapLPercent = (percent) => {
    return mapLeft + (sizes.mapWidth * ( -percent / 100 ) )
}

const mapTPercent = (percent) => {
    return mapTop + (sizes.mapDepth * ( -percent / 100 ) )
}

const uk =  [        50,	26.94271129 ];
const dk =  [52.87976962,	24.90073738 ];
const nyc = [27.64578834,	29.32501418 ];
const ca =  [25.98992081,	25.80828134 ];
const aus = [91.90856731,	75.57402155 ];
const den = [18.503,	    34.090      ];
const pr =  [31.353,	    45.774      ];

const cubeTextureLoader = new THREE.CubeTextureLoader()



const decals = [
  {
    name: "graphQl",
    position: [0.861,1.451,-1.249],
    rotation: [-1.711,0,0]
  },
  {
    name: "lambda",
    position: [0.345,0.788,-1.16],
    rotation: [-1.711,Math.PI,0]
  },
  {
    name: "typescript",
    position: [0.345,1.673,-1.278],
    rotation: [-1.711,Math.PI,0]
  },
  {
    name: "redwood",
    position: [0.935,0.935,-1.179],
    rotation: [-1.711,Math.PI,0]
  },
  {
    name: "figma",
    position: [0.788,0.387,-1.13],
    rotation: [-1.711,Math.PI,0]
  },
  {
    name: "react",
    position: [-0.466,0.566,-1.13],
    rotation: [-1.711,Math.PI,0]
  },
  {
    name: "threejs",
    position: [-0.614,1.083,-1.2],
    rotation: [-1.711,Math.PI,0]
  },
  {
    name: "blender",
    position: [-0.909,0.345,-1.101],
    rotation: [-1.711,Math.PI,0]
  },

]

const careerPoints = [
    {
        name: "dcTrade",
        position: [mapLPercent(dk[0]), 1.045, mapTPercent(dk[1])],
        rotation: [ THREE.MathUtils.radToDeg(3.5081117965086026) ,THREE.MathUtils.radToDeg(-0.20943951023931956),180],
        positionCamera: [-0.909,0.714,-1.056],
        model: null,
        cone: null,
        images: []
    },
    {
        name: "gameplan",
        position: [mapLPercent(aus[0]), 1.045, mapTPercent(aus[1])],
        rotation: [THREE.MathUtils.radToDeg(3.6651914291880923),
                    THREE.MathUtils.radToDeg(-0.6632251157578453),180],
        positionCamera: [-1.941,0.861,-1.3],
        model: null,
        images: [
            {
                file: "targetview.jpg",
                position: [-0.982,0.041,-0.761],
                size: [2048,4369],
                scale: 0.00008,
                rotation: [0,218,0],
                imagePlane: null
            },
            {
                file: "kangaroo.png",
                position: [-1.255,0.042,-0.843],
                size: [2560,1920],
                scale: 0.00008,
                rotation: [0,200,0],
                imagePlane: null
            },
            {
                file: "helicopter.png",
                position: [-1.425,0.041,-0.614],
                size: [2560,1920],
                scale: 0.00008,
                rotation: [0,197,0],
                imagePlane: null
            },
            {
                file: "meeting.png",
                position: [-1.428,0.043,-0.788],
                size: [2560,1920],
                scale: 0.00006,
                rotation: [0,220,0],
                imagePlane: null
            },
            {
                file: "workflow.png",
                position: [-0.982,0.042,-0.909],
                size: [2204,1364],
                scale: 0.0001,
                rotation: [0,191,0],
                imagePlane: null
            }
        ]
    },
    {
        name: "salesSilk",
        position: [mapLPercent(uk[0]), 1.045, mapTPercent(uk[1])],
        rotation: [ THREE.MathUtils.radToDeg(3.5081117965086026) ,THREE.MathUtils.radToDeg(-0.20943951023931956),180],
        positionCamera: [-0.909,0.714,-1.056],
        model: null,
        cone: null,
        images: [
          {
            file: "ironman.jpg",
            position: [-0.817,0.046,-0.516],
            size: [1512,2016],
            scale: 0.000075,
            rotation: [0,218,0],
            imagePlane: null
          },
          {
            file: "skynews.jpg",
            position: [-0.761,0.045,-0.392],
            size: [1134,851],
            scale: 0.00015,
            rotation: [0,198,0],
            imagePlane: null
          },
          // {
          //   file: "developerguide.jpg",
          //   position: [-0.917,0.041,-0.463],
          //   size: [1118,7879],
          //   scale: 0.00013,
          //   rotation: [0,198,0],
          //   imagePlane: null,
          // },
          {
            file: "allocator.png",
            position: [-0.687,0.043,-0.575],
            size: [2620,5584],
            scale: 0.00007,
            rotation: [0,208,0],
            imagePlane: null
          },
          {
            file: "userflows.png",
            position: [-0.548,0.044,-0.319],
            size: [2988,1794],
            scale: 0.0001,
            rotation: [0,198,0],
            imagePlane: null
          }
        ]
    },
    {
        name: "versioControls",
        position: [mapLPercent(ca[0]), 1.045, mapTPercent(ca[1])],
        rotation: [ THREE.MathUtils.radToDeg(3.351032163829113) ,THREE.MathUtils.radToDeg(-0.8203047484373349),180],
        positionCamera: [-1.277,0.493,-0.761],
        model: null,
        cone: null,
        images: []
    },
    {
        name: "xgPlatform",
        position: [mapLPercent(den[0]), 1.045, mapTPercent(den[1])],
        rotation: [ THREE.MathUtils.radToDeg(3.6) ,THREE.MathUtils.radToDeg(-0.6632251157578453),180],
        positionCamera: [-0.909,0.714,-0.835],
        model: null,
        cone: null,
        images: [
          {
            file: "teamsnow.jpg",
            position: [-0.392,0.046,-0.581],
            size: [2016, 1512],
            scale: 0.00012,
            rotation: [0,206,0],
            imagePlane: null
          },
          {
            file: "piplanning.png",
            position: [-0.354,0.046,-0.319],
            size: [2016, 1512],
            scale: 0.0001,
            rotation: [0,191,0],
            imagePlane: null
          },
          // {
          //   file: "linearslots.jpg",
          //   position: [-0.687,0.045,-0.687],
          //   size: [1606,10474],
          //   scale: 0.00016,
          //   rotation: [0,201,0],
          //   imagePlane: null
          // },
          // {
          //   file: "ds_select.png",
          //   position: [0.264,0.041,-0.319],
          //   size: [1137,12666],
          //   scale: 0.00016,
          //   rotation: [0,227,0],
          //   imagePlane: null
          // },
          {
            file: "designprocess.png",
            position: [-0.171,0.042,-0.466],
            size: [1631, 1250],
            scale: 0.00015,
            rotation: [0,191,0],
            imagePlane: null
          },
          {
            file: "designstudio.jpg",
            position: [-0.097,0.041,-0.245],
            size: [2268, 1701],
            scale: 0.0001,
            rotation: [0,201,0],
            imagePlane: null
          },

        ]
    },
    {
        name: "jmChocolat",
        position: [mapLPercent(pr[0]), 1.045, mapTPercent(pr[1])],
        rotation: [212,-12,180],
        positionCamera: [-0.8,1,-1.4],
        model: null,
        cone: null,
        images: []
    },
]


const navigation = {
    menu: [
        {
            id: "home",
            children: []
        },
        {
            id: "tech",
            children: [
                { page: typescriptHTML, id: "typescript" },
                { page: graphQlHTML, id: "graphQl" },
                { page: redwoodHTML, id: "redwood", },
                { page: lambdaHTML, id: "lambda" },
                { page: figmaHTML, id: "figma" },
                { page: reactHTML, id: "react", },
                { page: threejsHTML, id: "threejs" },
                { page: blenderHTML, id: "blender" },
            ]
        },
        {
            id: "projects",
            children: [
                { id: "dcTrade" },
                { id: "gameplan" },
                { id: "salesSilk" },
                { id: "versioControls" },
                { id: "xgPlatform" },
                { id: "jmChocolat" }
            ]
        },
        {
            id: "contact",
            page: contactHTML,
            children: []
        }
    ],
    selectedParent: "home",
    selectedChild: null
}


console.log("careerPoints", careerPoints);

const variables = {
  wf: 1,
};

const canvas = document.querySelector("canvas.webgl");
const scene = new THREE.Scene();


const camera = new THREE.PerspectiveCamera(
  60,
  sizes.width / sizes.height,
  0.1,
  100
);

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
window.addEventListener("DOMContentLoaded", (event) => {
  window.addEventListener("click", handleClick);
  window.addEventListener("touchstart", handleClick);
});

const closeOverlay = () => {
  if(document.querySelector("#UI.active")){
    document.querySelector("#UI").classList.add(["next"]);
    if(document.querySelector(".children .selected")) document.querySelector(".children .selected").classList.remove(["selected"]);
  
    setTimeout( () => { 
      document.querySelector("#UI").classList.remove("active");
      document.querySelector("#UI").classList.remove("next");
    }, 1000);
  }
}

const handleClick = (event) => {
  scrollY = window.scrollY;

  console.log("click", event);

    if (event.target.id == "home")      {closeOverlay(); setState_home(camera, variables, scene, objects) };
    if (event.target.id == "tech")      {closeOverlay(); setState_tech(camera, variables, scene, objects, decals) };
    if (event.target.id == "projects")  {closeOverlay(); setState_projects(camera, variables, scene, objects, sizes, careerPoints) };
    if (event.target.id == "contact")   {setState_overview(camera, variables, scene, objects) };


    var allIds = [];
    var parents = [];
    var children = [];

    navigation.menu.forEach(m => {
        allIds.push( {  
          id: m.id, 
          parent: null, 
          firstChild: m.children.length > 0 ? m.children[0] : null, 
          page: m.page ? m.page : false
        } );
        m.children.forEach(c => {
            allIds.push( {
                id: c.id, 
                parent: m.id, 
                page: c.page ? c.page : false}  );
            children.push(c.id);
        });
        if(m.children.length > 0) parents.push(m.id);
    });

    console.log("classList",event.target.classList);

    console.log("allIds",allIds);

    var targetId = event.target.id;

    if(targetId == "UI-OUTSIDE"){
      closeOverlay()
    }
    else if (event.target.classList.contains("nav-item")){

        allIds.forEach(id => {
            var selector = "#" + id.id;
            console.log("id",selector);
            document.querySelector(selector).classList.remove("selected");
        });

        
        
        
        if(!children.includes(targetId)) parents.forEach(p => {
            document.querySelector(`#${p}-children`).classList.add("hidden");
        })

        var idItem = allIds.find(i => i.id == targetId);

        console.log("ID ITEM", idItem);

        document.querySelector("#" + targetId).classList.add("selected");
        if(idItem.parent != null) document.querySelector("#" + idItem.parent).classList.add("selected");

        if(idItem.firstChild != null){
          setTimeout( () => { 
            document.querySelector("#" + idItem.id + "-children").classList.remove("hidden");
          }, 5000);
        }

        if(idItem.page){
          // if(document.querySelector("#UI.active")){
          //   document.querySelector("#UI").classList.add("next");
          //   document.querySelector("#UI").classList.remove("next");
          // }


          var delay = 0;       
          if(document.querySelector("#UI.active")){
              document.querySelector("#UI").classList.add("next");
              delay = 500;
          }

          setTimeout( () => { 
                  document.querySelector("#UI").classList.remove(["next"]);
                  document.querySelector("#UI").classList.remove(["active"]);
                  document.querySelector("#UI-CONTENT").innerHTML = idItem.page;
        
                  setTimeout( () => { 
                    document.querySelector("#UI").classList.add("active");
                  }, 100);
          }, delay);
        }
    }


  var raycaster = new THREE.Raycaster();
  var mouse = new THREE.Vector2();

  event.preventDefault();

  mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
  mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  console.log("raycaster", raycaster);

  var intersectsGQL = raycaster.intersectObjects([objects.decalGraphQL]);
  var intersectsAWS = raycaster.intersectObjects([objects.decalAWS]);

  if (intersectsGQL.length > 0) {
    document.querySelector("#panel").classList.remove("hidden");
    document.querySelector("#graphql").classList.remove("hidden");
    document.querySelector("#aws").classList.add("hidden");
  } else if (intersectsAWS.length > 0) {
    document.querySelector("#panel").classList.remove("hidden");
    document.querySelector("#graphql").classList.add("hidden");
    document.querySelector("#aws").classList.remove("hidden");
  } else {
    document.querySelector("#panel").classList.add("hidden");
    document.querySelector("#graphql").classList.add("hidden");
    document.querySelector("#aws").classList.add("hidden");
  }

};

const setState_initial = () => {


  camera.position.set(
    0.762,
    0.131, //objects.plant.position.y,
    -0.171
  );
  camera.rotation.set(Math.PI * 1.05, Math.PI * 2, Math.PI);
  objects.table.add(camera);
  wireframeSim(1, scene, objects);
};

const updateAllMaterials = () => {
  scene.traverse((child) => {
    if (child.isMesh && child.material.isMeshStandardMaterial) {
      child.castShadow = true;
      child.receiveShadow = true;
      // child.material.transparent = false;
    }
  });
};


const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
//   antialias: true,
});

renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};


const loadGui = () => {

  const gui = new GUI();

  gui.close();
  
  var guiFocus;

  
  var guiFocus = objects.coffeeCupModel;
  const cupPosition = gui.addFolder( `cupPosition` );
  cupPosition.add(guiFocus.position, 'x', -sizes.tableWidth,sizes.tableWidth, 0.001)
      .onChange(v => guiFocus.position.set(v,  guiFocus.position.y, 
                                                          guiFocus.position.z));

  cupPosition.add(guiFocus.position, 'y', -sizes.tableWidth,sizes.tableWidth, 0.001)
  .onChange(v => guiFocus.position.set(    guiFocus.position.x,  
                                                      v, 
                                                      guiFocus.position.z));
  
  cupPosition.add(guiFocus.position, 'z', -sizes.tableWidth,sizes.tableWidth, 0.001)
  .onChange(v => guiFocus.position.set(    guiFocus.position.x,  
                                                      guiFocus.position.y, 
                                                      v));
  cupPosition.add(guiFocus.rotation, 'y', -360,360, 1)
  .onChange(v => guiFocus.rotation.set(    guiFocus.rotation.x,  
                                                      THREE.MathUtils.degToRad(v), 
                                                      guiFocus.rotation.z));

              
                                                      /*
  guiFocus = objects.tennis;
  const tennisPosition = gui.addFolder( `tennisPosition` );
  tennisPosition.add(guiFocus.position, 'x', -sizes.tableWidth,sizes.tableWidth, 0.001)
      .onChange(v => guiFocus.position.set(v,  guiFocus.position.y, 
                                                          guiFocus.position.z));

  tennisPosition.add(guiFocus.position, 'y', -sizes.tableWidth,sizes.tableWidth, 0.001)
  .onChange(v => guiFocus.position.set(    guiFocus.position.x,  
                                                      v, 
                                                      guiFocus.position.z));
  
  tennisPosition.add(guiFocus.position, 'z', -sizes.tableWidth,sizes.tableWidth, 0.001)
  .onChange(v => guiFocus.position.set(    guiFocus.position.x,  
                                                      guiFocus.position.y, 
                                                      v));
  

  tennisPosition.add(guiFocus.rotation, 'x', -360,360, 1)
  .onChange(v => guiFocus.rotation.set(    
                                            THREE.MathUtils.degToRad(v), 
                                            guiFocus.rotation.y,  
                                            guiFocus.rotation.z));

  tennisPosition.add(guiFocus.rotation, 'y', -360,360, 1)
  .onChange(v => guiFocus.rotation.set(    
                                            guiFocus.rotation.x,  
                                            THREE.MathUtils.degToRad(v), 
                                            guiFocus.rotation.z));
                                            */

guiFocus = objects.plant;
const plantPosition = gui.addFolder( `plantPosition` );
plantPosition.add(guiFocus.position, 'x', -sizes.tableWidth,sizes.tableWidth, 0.001)
    .onChange(v => guiFocus.position.set(v,  guiFocus.position.y, 
                                                        guiFocus.position.z));

plantPosition.add(guiFocus.position, 'y', -sizes.tableWidth,sizes.tableWidth, 0.001)
.onChange(v => guiFocus.position.set(    guiFocus.position.x,  
                                                    v, 
                                                    guiFocus.position.z));

 plantPosition.add(guiFocus.position, 'z', -sizes.tableWidth,sizes.tableWidth, 0.001)
.onChange(v => guiFocus.position.set(    guiFocus.position.x,  
                                                    guiFocus.position.y, 
                                                    v));


plantPosition.add(guiFocus.rotation, 'x', -360,360, 1)
.onChange(v => guiFocus.rotation.set(    
                                          THREE.MathUtils.degToRad(v), 
                                          guiFocus.rotation.y,  
                                          guiFocus.rotation.z));

plantPosition.add(guiFocus.rotation, 'y', -360,360, 1)
.onChange(v => guiFocus.rotation.set(    
                                          guiFocus.rotation.x,  
                                          THREE.MathUtils.degToRad(v), 
                                          guiFocus.rotation.z));
                                          



  const camera_position = gui.addFolder( 'camera_position' );
  camera_position.add(camera.position, 'x', -3,3, 0.001)
      .onChange(v => camera.position.set(v,  camera.position.y, 
                                                          camera.position.z));

  camera_position.add(camera.position, 'y', -3,3, 0.001)
  .onChange(v => camera.position.set(    camera.position.x,  
                                                      v, 
                                                      camera.position.z));
  
  camera_position.add(camera.position, 'z', -3, 3, 0.001)
  .onChange(v => camera.position.set(    camera.position.x, camera.position.y,
                                                      v ));



  const camera_rotation = gui.addFolder( 'camera_rotation' );
  camera_rotation.add(camera.rotation, 'x', -360,360, 1)
  .onChange(v => camera.rotation.set(    
                                      THREE.MathUtils.degToRad(v), 
                                      camera.rotation.y,  
                                      camera.rotation.z
                                  ));

  camera_rotation.add(camera.rotation, 'y', -360,360, 1)
  .onChange(v => camera.rotation.set(    camera.rotation.x,  
                                                      THREE.MathUtils.degToRad(v), 
                                                      camera.rotation.z));


      const room_light = gui.addFolder( 'room_light' );

      room_light.add(objects.roomLight.position, "x", -5, 5, 0.1)
                  .onChange(v => objects.roomLight.position.set(v,  objects.roomLight.position.y, objects.roomLight.position.z));

      room_light.add(objects.roomLight.position, "y", -5, 5, 0.1)
                  .onChange(v => objects.roomLight.position.set(objects.roomLight.position.x, v, objects.roomLight.position.z));
      room_light.add(objects.roomLight.position, "z", -5, 5, 0.1)
                  .onChange(v => objects.roomLight.position.set(objects.roomLight.position.x, objects.roomLight.position.y, v));

      room_light.add(objects.roomLight, "intensity", 0, 10, 0.1)
                  .onChange(v => objects.intensity = v);
                  
                           
      setTimeout( () => {
        
        careerPoints.forEach( cp => {

            cp.images.forEach(image => {

                var guiFocus = image;
                const imagePlane_position = gui.addFolder( `${cp.name} ${image.file}` );
                imagePlane_position.close();
                imagePlane_position.add(guiFocus.imagePlane.position, 'x', -sizes.tableWidth,sizes.tableWidth, 0.001)
                    .onChange(v => guiFocus.imagePlane.position.set(v,  guiFocus.imagePlane.position.y, 
                                                                        guiFocus.imagePlane.position.z));
        
                imagePlane_position.add(guiFocus.imagePlane.position, 'y', -sizes.tableWidth,sizes.tableWidth, 0.001)
                .onChange(v => guiFocus.imagePlane.position.set(    guiFocus.imagePlane.position.x,  
                                                                    v, 
                                                                    guiFocus.imagePlane.position.z));
                
                imagePlane_position.add(guiFocus.imagePlane.position, 'z', -sizes.tableWidth,sizes.tableWidth, 0.001)
                .onChange(v => guiFocus.imagePlane.position.set(    guiFocus.imagePlane.position.x,  
                                                                    guiFocus.imagePlane.position.y, 
                                                                    v));
                imagePlane_position.add(guiFocus.rotation, 1, -360,360, 1)
                .onChange(v => guiFocus.imagePlane.rotation.set(    guiFocus.imagePlane.rotation.x,  
                                                                    THREE.MathUtils.degToRad(v), 
                                                                    guiFocus.imagePlane.rotation.z));
            })
        });

        decals.forEach( decal => {
              const decal_position = gui.addFolder( `${decal.name}` );
              decal_position.close();
              decal_position.add(decal.model.position, 'x', -sizes.tableWidth,sizes.tableWidth, 0.001)
                  .onChange(v => decal.model.position.set(v,  decal.model.position.y, 
                                                                      decal.model.position.z));
      
              decal_position.add(decal.model.position, 'y', -sizes.tableWidth,sizes.tableWidth, 0.001)
              .onChange(v => decal.model.position.set(    decal.model.position.x,  
                                                                  v, 
                                                                  decal.model.position.z));
              
              decal_position.add(decal.model.position, 'z', -sizes.tableWidth,sizes.tableWidth, 0.001)
              .onChange(v => decal.model.position.set(    decal.model.position.x,  
                                                                  decal.model.position.y, 
                                                                  v));

              decal_position.add(decal.model.rotation, "x", -3,3, 0.001)
              .onChange(v => decal.model.rotation.set(    
                v, 
                decal.model.rotation.y,  
                decal.model.rotation.z
                ));

              decal_position.add(decal.model.rotation, "y", -3,3, 0.001)
              .onChange(v => decal.model.rotation.set(    
                          decal.model.rotation.x,  
                          v, 
                          decal.model.rotation.z                                                                  
                          ));

              decal_position.add(decal.model.rotation, "z", -3,3, 0.001)
              .onChange(v => decal.model.rotation.set(    
                          decal.model.rotation.x,  
                          decal.model.rotation.y,                                                            
                          v, 
                ));
      });


      const desktop_position = gui.addFolder( `desktop_position` );
      desktop_position.close();
      desktop_position.add(objects.desktop.position, 'x', -sizes.tableWidth,sizes.tableWidth, 0.001)
          .onChange(v => objects.desktop.position.set(v,  objects.desktop.position.y, 
                                                              objects.desktop.position.z));

      desktop_position.add(objects.desktop.position, 'y', -sizes.tableWidth,sizes.tableWidth, 0.001)
      .onChange(v => objects.desktop.position.set(    objects.desktop.position.x,  
                                                          v, 
                                                          objects.desktop.position.z));
      
      desktop_position.add(objects.desktop.position, 'z', -sizes.tableWidth,sizes.tableWidth, 0.001)
      .onChange(v => objects.desktop.position.set(    objects.desktop.position.x,  
                                                          objects.desktop.position.y, 
                                                          v));

      desktop_position.add(objects.desktop.rotation, "x", -3,3, 0.001)
      .onChange(v => objects.desktop.rotation.set(    
        v, 
        objects.desktop.rotation.y,  
        objects.desktop.rotation.z
        ));

      desktop_position.add(objects.desktop.rotation, "y", -3,3, 0.001)
      .onChange(v => objects.desktop.rotation.set(    
                  objects.desktop.rotation.x,  
                  v, 
                  objects.desktop.rotation.z                                                                  
                  ));

      desktop_position.add(objects.desktop.rotation, "z", -3,3, 0.001)
      .onChange(v => objects.desktop.rotation.set(    
                  objects.desktop.rotation.x,  
                  objects.desktop.rotation.y,                                                            
                  v, 
        ));


        desktop_position.add(objects.desktop.scale, "x", 0.1,4, 0.001)
        .onChange(v => objects.desktop.scale.set(    
                    v,  
                    objects.desktop.scale.y,                                                            
                    objects.desktop.scale.z, 
          ));

          desktop_position.add(objects.desktop.scale, "z", 0.1,4, 0.001)
          .onChange(v => objects.desktop.scale.set(    
                      objects.desktop.scale.x,  
                      objects.desktop.scale.y, 
                      v,                                                            
            ));


      }
      , 5000);
}

const ready = () => {
    updateAllMaterials();

    setState_initial();
    setState_home(camera, variables, scene, objects);
    //setState_projects(camera, variables, scene, objects, sizes, careerPoints, gui);
    // setState_macside(camera, variables, scene, objects, sizes, careerPoints, gui);


    
    //loadGui();
    tick();
  };



objects.env = cubeTextureLoader.load([
  '/environmentMaps/3/px.png',
  '/environmentMaps/3/nx.png',
  '/environmentMaps/3/py.png',
  '/environmentMaps/3/ny.png',
  '/environmentMaps/3/pz.png',
  '/environmentMaps/3/nz.png'
]);

// const textureLoader = new THREE.TextureLoader();
// objects.env = textureLoader.load('/environmentMaps/city.png');
// objects.env.mapping = THREE.EquirectangularReflectionMapping;
objects.env.colorSpace = THREE.SRGBColorSpace;
// scene.background = objects.env;


// scene.background = new THREE.Color("#000");

buildScene(scene, sizes, objects, careerPoints);
loadModels(objects, ready, scene, sizes, careerPoints, decals);
