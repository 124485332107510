import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import GUI from 'lil-gui'
import gsap from 'gsap'


export const wireframeSim = (level, scene, objects) => {

    scene.traverse((child) => {
        if(child.isMesh && child.material.isMeshStandardMaterial 
            && !child.name.includes("cp.image")
            && !child.name.includes("coffeeCupModel")
        )
        {
            child.material.transparent = true;
            child.material.opacity = 1 - level;
        }
    })

    objects.macbookModel.traverse((child) => {
        if(child.isMesh && child.material.isMeshStandardMaterial)
        { 
            child.material.opacity = 1;
        }
    })

    objects.macbookModelSolid.traverse((child) => {
        if(child.isMesh && child.material.isMeshStandardMaterial)
        {
            child.material.opacity = 0;
        }
    })

    objects.plant.traverse((child) => {
        if(child.isMesh && child.material.isMeshStandardMaterial)
        {
            child.material.opacity =  1 - level;
        }
    })

    objects.flowerModelWireframe.traverse((child) =>
    {
        if(child.isMesh)
        {
            child.material.opacity = level;
        }
    })
}

export const solidifyMacbook = (level, scene, objects) => {

    if(level == 1){
        objects.table.remove(objects.macbookModelSolid)
    } else {
        objects.table.add(objects.macbookModelSolid)
    }

    objects.macbookModel.traverse((child) => {
        if(child.isMesh && child.material.isMeshStandardMaterial)
        {
            child.material.opacity = level;
        }
    })

    objects.macbookModelSolid.traverse((child) => {
        if(child.isMesh && child.material.isMeshStandardMaterial)
        {
            child.material.opacity = 1 - level;
        }
    })
}
