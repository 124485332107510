import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import GUI from 'lil-gui'
import gsap from 'gsap'

export const buildScene = (scene, sizes, objects, careerPoints) => {

    const sceneFloor = new THREE.Mesh(
        new THREE.PlaneGeometry(sizes.sceneWidth, sizes.sceneDepth),
        new THREE.MeshStandardMaterial({
            color: '#444444',
            metalness: 0,
            roughness: 0.5
        })
    )
    sceneFloor.receiveShadow = true
    sceneFloor.rotation.x = - Math.PI * 0.5
    sceneFloor.position.set(0,0,0);
    // scene.add(sceneFloor)

    const room = new THREE.Group();
    const roomX = (sizes.sceneWidth - sizes.roomWidth) * -0.5;
    const roomZ = (sizes.sceneDepth - sizes.roomDepth) * -0.5;
    room.position.set(roomX, 0.01, roomZ);

    

    scene.add(room);


    // const roomLight = new THREE.PointLight("#a5b4fc", 1.3)
    const roomLight = new THREE.PointLight("#FFF", 1.3)
    roomLight.castShadow = true
    roomLight.shadow.mapSize.set(1024, 1024)
    roomLight.shadow.camera.far = 15
    roomLight.shadow.camera.left = - 7
    roomLight.shadow.camera.top = 7
    roomLight.shadow.camera.right = 7
    roomLight.shadow.camera.bottom = - 7
    roomLight.position.set(0.2,1.8,-0.3)
    roomLight.shadow.normalBias = 0.027;

    objects.roomLight = roomLight;
    room.add(roomLight);

    const wallColour = "#555";

    const backWallLeft = new THREE.Mesh(
        new THREE.BoxGeometry(sizes.tableGap * 0.5, sizes.roomHeight, sizes.wallThickness),
        new THREE.MeshStandardMaterial({
            color: wallColour,
            metalness: 0,
            roughness: 0.5
        })
    )

    const backWallLeftX = sizes.roomWidth * 0.5 - (0.5* sizes.wallThickness) - (sizes.tableGap * 0.25)
    backWallLeft.position.set(backWallLeftX, sizes.roomHeight * 0.5, sizes.roomDepth * 0.5);
    room.add(backWallLeft);


    const backWallRightWidth = sizes.roomWidth - (sizes.tableGap * 0.5) - sizes.windowWidth + sizes.wallThickness;
    const backWallRight = new THREE.Mesh(
        new THREE.BoxGeometry(backWallRightWidth, sizes.roomHeight, sizes.wallThickness),
        new THREE.MeshStandardMaterial({
            color: wallColour,
            metalness: 0,
            roughness: 0.5
        })
    )

    const backWallRightX = (sizes.roomWidth ); 
    backWallRight.position.set(-sizes.windowWidth + sizes.wallThickness + sizes.wallThickness , sizes.roomHeight * 0.5, sizes.roomDepth * 0.5);
    room.add(backWallRight);



    const windowBottom = new THREE.Mesh(
        new THREE.BoxGeometry(sizes.windowWidth, sizes.windowLedgeHeight, sizes.wallThickness),
        new THREE.MeshStandardMaterial({
            color: wallColour,
            metalness: 0,
            roughness: 0.5
        })
    )
    const windowBottomX = (sizes.roomWidth * 0.5) - (sizes.windowWidth * 0.5) - (sizes.tableGap * 0.5)
    windowBottom.position.set(windowBottomX, sizes.windowLedgeHeight * 0.5, sizes.roomDepth * 0.5);
    room.add(windowBottom);


    const windowTopHeight = sizes.roomHeight - sizes.windowLedgeHeight - sizes.windowHeight;
    const windowTop = new THREE.Mesh(
        new THREE.BoxGeometry(sizes.windowWidth, windowTopHeight, sizes.wallThickness),
        new THREE.MeshStandardMaterial({
            color: wallColour,
            metalness: 0,
            roughness: 0.5
        })
    )

    const windowTopX = (sizes.roomWidth * 0.5) - (sizes.windowWidth * 0.5) - (sizes.tableGap * 0.5)
    windowTop.position.set(windowTopX, sizes.roomHeight - (windowTopHeight * 0.5), sizes.roomDepth * 0.5);
    room.add(windowTop);



    const leftWall = new THREE.Mesh(
        new THREE.BoxGeometry(sizes.wallThickness, sizes.roomHeight, sizes.roomDepth),
        new THREE.MeshStandardMaterial({
            color: wallColour,
            metalness: 0,
            roughness: 0.5
        })
    )
    const wallX = sizes.roomWidth * 0.5 - (0.5* sizes.wallThickness)
    leftWall.position.set(wallX, sizes.roomHeight * 0.5, 0);
    room.add(leftWall);


    const roomFloor = new THREE.Mesh(
        new THREE.PlaneGeometry(sizes.roomWidth, sizes.roomDepth),
        new THREE.MeshStandardMaterial({
            color: '#333',
            metalness: 0,
            roughness: 0.5
        })
    )
    roomFloor.receiveShadow = true
    roomFloor.rotation.x = - Math.PI * 0.5
    room.add(roomFloor);

    objects.table = new THREE.Group();
    objects.tableX = ((sizes.roomWidth - sizes.tableWidth - sizes.tableGap) * 0.5);
    objects.tableZ = (sizes.roomDepth - sizes.tableDepth) * 0.5;

    objects.table.position.set(objects.tableX, sizes.tableHeight, objects.tableZ);
    room.add(objects.table);

    objects.tableSurface = new THREE.Mesh(
        new THREE.BoxGeometry(sizes.tableWidth, sizes.tableDepth, sizes.tableThickness),
        new THREE.MeshStandardMaterial({
            color: '#777',
            metalness: 0,
            roughness: 0.5
        })
    )
    objects.tableSurface.rotation.x = - Math.PI * 0.5;
    objects.table.add(objects.tableSurface);

    objects.macbookPlaceholder = new THREE.Mesh(
        new THREE.BoxGeometry(sizes.macbookWidth, sizes.macbookHeight, sizes.macbookDepth),
        new THREE.MeshStandardMaterial({
            color: '#0F0',
            metalness: 0,
            roughness: 0.5
        })
    )
    objects.macbookPlaceholder.position.set(sizes.tableWidth * 0.25, (sizes.macbookHeight * 0.5), (sizes.tableDepth * 0.6)  * -0.5);
    objects.table.add(objects.macbookPlaceholder);

    /**
     * Lights
     */
    // const ambientLight = new THREE.AmbientLight(0xffffff, 2.4)
    // scene.add(ambientLight)

    const lightPlaceholder = new THREE.Mesh(
        new THREE.SphereGeometry(0.5),
        new THREE.MeshStandardMaterial({
            color: wallColour,
            metalness: 0,
            roughness: 0.5
        })
    )
    lightPlaceholder.position.set(-0.5,4,0);
    scene.add(lightPlaceholder);


    const directionalLight = new THREE.PointLight("#6366f1", 10)
    directionalLight.castShadow = true
    directionalLight.shadow.mapSize.set(1024, 1024)
    directionalLight.shadow.camera.far = 15
    directionalLight.shadow.camera.left = - 7
    directionalLight.shadow.camera.top = 7
    directionalLight.shadow.camera.right = 7
    directionalLight.shadow.camera.bottom = - 7
    directionalLight.position.copy(lightPlaceholder.position)
    directionalLight.shadow.normalBias = 0.027
    scene.add(directionalLight);


    const mapPage = new THREE.Mesh(
        new THREE.BoxGeometry(sizes.mapWidth, 0.001, sizes.mapDepth),
        new THREE.MeshStandardMaterial({
            color: '#00F',
            metalness: 0,
            roughness: 0.5
        })
    )
    const map = new THREE.Group();
    objects.table.add(map);
    map.position.set(
        (sizes.tableWidth * -0.5) + (sizes.mapWidth/2) + 0.05, 
        (sizes.tableThickness),
        (sizes.tableDepth*-0.5) + (sizes.mapDepth/2)  + 0.05
    );
    objects.map = map;

    objects.map.add(mapPage);



    const coneGroup = new THREE.Group();

    objects.coneGroup = coneGroup;

    careerPoints.forEach(cp => {
        cp.cone = new THREE.Mesh(
            new THREE.ConeGeometry(sizes.coneRadius, -sizes.coneHeight, 50),
            new THREE.MeshBasicMaterial({
                transparent: true,
                color: "#F00",
                opacity: 0.7
            })
        );

        cp.cone.position.set(cp.position[0] - (sizes.coneRadius/2),sizes.coneHeight,cp.position[2] - (sizes.coneRadius/2));
        objects.coneGroup.add( cp.cone );
    });

    //objects.map.add(coneGroup);
}